import styles from './TimePicker.module.css';

export default function TimePicker({
    name,
    register,
    includeSeconds = true,
    includeMinutes = true,
    includeHours = true
}) {
    const toRender = [];

    includeHours &&
        toRender.push(
            <input
                className={`form-control ${styles.timeButton}`}
                key={'hours'}
                placeholder='hours'
                type='number'
                min='0'
                {...register(`${name}.hours`, {
                    valueAsNumber: true
                })}
            />
        );
    if (includeMinutes) {
        if (toRender.length !== 0) toRender.push(':');
        toRender.push(
            <input
                className={`form-control ${styles.timeButton}`}
                placeholder='minutes'
                key={'minutes'}
                type='number'
                min='0'
                max='59'
                {...register(`${name}.minutes`, {
                    valueAsNumber: true
                })}
            />
        );
    }
    if (includeSeconds) {
        if (toRender.length !== 0) toRender.push(':');
        toRender.push(
            <input
                className={`form-control ${styles.timeButton}`}
                placeholder='seconds'
                key={'seconds'}
                type='number'
                min='0'
                max='59'
                {...register(`${name}.seconds`, {
                    valueAsNumber: true
                })}
            />
        );
    }

    return <div className={styles.wrapper}>{toRender}</div>;
}
