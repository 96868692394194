import { useState, useEffect, useMemo } from 'react';
import API from '../../../Utils/api';
import { useUser } from '../../../Context/useUser';
import Table from '../../../Components/Table/Table';
import Utils from '../../../Utils';
import Line from '../../../Components/Charts/Line/Line';
import styles from './RaceAnalysisPage.module.css';
import { useVisitingUser } from '../../../Hooks/useVisitingUser';
import { useParams } from 'react-router';

const FixedNumber = ({ value }) => {
    if (!value) return null;
    return <div>{value.toFixed(2)}</div>;
};

const raceColumns = [
    {
        Header: 'Race Date',
        Cell: ({ row }) => <div>{new Date(row.original.raceDate).toLocaleDateString()}</div>
    },
    {
        Header: 'Race Type',
        accessor: 'raceType'
    },
    {
        Header: 'Race Location',
        accessor: 'raceLocation'
    },
    {
        Header: 'Created By',
        accessor: 'createdBy'
    },
    {
        Header: 'Updated',
        Cell: ({ row }) =>
            row.original.updatedAt && <div>{new Date(row.original.updatedAt).toLocaleString()}</div>
    }
];

const splitTableColumns = [
    {
        Header: '',
        accessor: 'volume'
        // Cell: ({ row }) => <div>{new Date(row.original.createdAt).toLocaleDateString()}</div>
    },
    {
        Header: 'Duration (sec)',
        accessor: 'duration'
        // Cell: ({ row }) => <div>{row.original.css.toFixed(2)}</div>
    },
    {
        Header: 'T (sec)',
        accessor: 'T'
        // Cell: ({ row }) => <div>{row.original.css.toFixed(2)}</div>
    },
    {
        Header: 'Velocity (m/sec)',
        accessor: 'velocity',
        Cell: FixedNumber
    },
    {
        Header: 'Stroke Rate [SR]',
        accessor: 'strokeRate',
        Cell: FixedNumber
    },
    {
        Header: 'Stroke Length [SL]',
        accessor: 'strokeLength',
        Cell: FixedNumber
    },
    {
        Header: 'Stroke Index [SI]',
        accessor: 'strokeIndex',
        Cell: FixedNumber
    }
];

const { red, green, blue } = Utils.randomColorGenerator();

export default function RaceAnalysisPage () {
    const [testData, setTestData] = useState([]);
    const [race, selectRace] = useState();
    const [splits, selectSplits] = useState([]);

    const { user } = useUser();

    const { athleteId } = useParams();

    const visitedUser = useVisitingUser(athleteId);

    const currentUser = athleteId ? visitedUser : user;

    const fetchAndSetData = async currentUser => {
        if (!currentUser) return;
        const { data: races } = await API.getRacesByUserId(currentUser._id);
        setTestData(races);
    };

    const [selectedRowIndex, setSelectedRowIndex] = useState(null);

    const handleRaceSelect = race => {
        console.log(race);
        setSelectedRowIndex(0); // Update selected row index
        selectRace(race);
    };

    useEffect(() => {
        fetchAndSetData(currentUser);
    }, [currentUser]);

    useEffect(() => {
        if (testData.length > 0) {
            selectRace(testData[testData.length - 1]);
        }
    }, [testData]);

    useEffect(() => {
        if (race) {
            selectSplits(race.splits);
        }
    }, [race]);

    const splitVolumes = splits.map(split => split.volume);

    const labels = splitVolumes.reduce(
        (acc, volume) => {
            const lastValue = acc[acc.length - 1] || 0;
            const sum = lastValue + volume;
            return [...acc, sum];
        },
        [0]
    );

    const chart1Dataset = [
        {
            label: 'Stroke Rate [SR] (cycles/min)',
            data: [0, ...splits.map(split => split.strokeRate)],
            borderColor: `rgb(${blue}, ${red}, ${green})`,
            backgroundColor: `rgba(${blue}, ${red}, ${green}, 0.5)`
        },
        {
            label: 'Duration (sec)',
            data: [0, ...splits.map(split => split.duration)],
            borderColor: `rgb(${red}, ${green}, ${blue})`,
            backgroundColor: `rgba(${red}, ${green}, ${blue}, 0.5)`
        }
    ];

    const chart2Dataset = [
        {
            label: 'Velocity (m/sec)',
            data: [0, ...splits.map(split => split.velocity)],
            borderColor: `rgb(${red}, ${green}, ${blue})`,
            backgroundColor: `rgba(${red}, ${green}, ${blue}, 0.5)`
        },
        {
            label: 'Stroke Length [SL] (m/cycle)',
            data: [0, ...splits.map(split => split.strokeLength)],
            borderColor: `rgb(${green}, ${red}, ${blue})`,
            backgroundColor: `rgba(${green}, ${red}, ${blue}, 0.5)`
        },
        {
            label: 'Stroke Index [SR] (m^2/cycles/sec)',
            data: [0, ...splits.map(split => split.strokeIndex)],
            borderColor: `rgb(${blue}, ${green}, ${red})`,
            backgroundColor: `rgba(${blue}, ${green}, ${red}, 0.5)`
        }
    ];

    const transformDataWithVolRanges = (splits) => {
        let cumulative = 0; 
        return splits.map((split) => {
            const currentVol = split.volume; 
            const start = cumulative;
            const end = cumulative + currentVol;
            cumulative = end; // Update cumulative for the next iteration
            return {
                ...split, 
                volume: `${start} - ${end}`, 
            };
        });
    };
    
    const transformedData = race ? transformDataWithVolRanges(race.splits) : [];
    




    return (
        <div className={styles.pageWrap}>
            <h4 className={styles.title + ' island'}> Race Analysis</h4>

            <div className={styles.dataVisualizationWrap}>
                <div className={styles.graph + ' island'}>
                    <Line titleX={'Distance (m)'} datasets={chart1Dataset} labels={labels} />
                </div>
                <div className={styles.graph + ' island'}>
                    <Line titleX={'Distance (m)'} datasets={chart2Dataset} labels={labels} />
                </div>
            </div>
            <div className={styles.dataTablesWrap + ' island'}>
                <Table
                    columns={raceColumns}
                    data={testData}
                    onRowClick={handleRaceSelect}
                    selected={race}
                    />
            </div>
            <div className={styles.dataTablesWrap + ' island'}>
                {race && (
                    <Table
                        columns={splitTableColumns}
                        data={transformedData}
                        //data={race.splits}
                        selectedRowIndex={selectedRowIndex} // Pass selected row index to Table component
                        style={{ cursor: 'pointer' }}
                    />
                )}
            </div>
        </div>
    );
}