import { useEffect, useMemo, useState } from 'react';
import API from '../../../Utils/api';

import styles from './AthleteSearchPage.module.css';
import UserPreview from '../../../Components/UserPreview/UserPreview';
import Modal from '../../../Components/Modal/Modal';
import InviteAthleteModal from './InviteAthleteModal';

import { FaCheck, FaRegClock } from 'react-icons/fa6';
import CreateAthleteModal from './CreateAthleteModal';
import InfoAthleteModal from './InfoAthleteModal';
import { useUser } from '../../../Context/useUser';
import { useParams } from 'react-router';
import { useVisitingUser } from '../../../Hooks/useVisitingUser';

export default function AthleteSearchPage() {
    const { user } = useUser();

    const { coachId } = useParams();

    const visitedUser = useVisitingUser(coachId);

    const currentUser = coachId ? visitedUser : user;

    const [relationships, setRelationships] = useState(null);
    const [search, setSearch] = useState('');

    const [inviteAthleteModal, setInviteAthleteModal] = useState(null);
    const [createAthleteModal, setCreateAthleteModal] = useState(null);
    const [infoAthleteModal, setinfoAthleteModal] = useState(null);

    const fetchAndSetRelationships = async currUser => {
        if (!currUser) return;
        const { data: relationships } = await API.getAthletesById(currUser._id);
        setRelationships(relationships.reverse());
    };

    useEffect(() => {
        fetchAndSetRelationships(currentUser);
    }, [currentUser]);

    const athleteIslands = useMemo(() => {
        if (!relationships) return [];
        const _search = search.toLowerCase();
        return relationships
            ?.filter(
                r =>
                    r.athlete.firstName?.toLowerCase().startsWith(_search) ||
                    r.athlete.lastName?.toLowerCase().startsWith(_search) ||
                    r.athlete.email?.toLowerCase().startsWith(_search) ||
                    r.athlete.type?.toLowerCase().startsWith(_search)
            )
            .map(relationship => (
                <div
                    key={relationship._id}
                    className={styles.athletePreview + ' island'}
                    onClick={
                        () => setinfoAthleteModal(relationship)
                        // relationship.status === 'Active' ? onAthleteClick(relationship.athlete) : ''
                    }
                >
                    <UserPreview
                        user={relationship.athlete}
                        statusIcon={
                            relationship.status === 'Active' ? (
                                <FaCheck color={'Green'} size={24} />
                            ) : (
                                <FaRegClock color={'Orange'} size={24} />
                            )
                        }
                    />
                </div>
            ));
    }, [relationships, search]);

    const createUserInviteModal = () => {
        if (!inviteAthleteModal) return null;

        return (
            <Modal
                closeCB={() => setInviteAthleteModal(false)}
                title={'Invite Athlete'}
                children={
                    <InviteAthleteModal
                        coachId={currentUser._id}
                        onClose={() => {
                            setRelationships([]);
                            fetchAndSetRelationships(currentUser);
                            setInviteAthleteModal(false);
                        }}
                    />
                }
            />
        );
    };

    const createCreationModal = () => {
        if (!createAthleteModal) return null;
        return (
            <Modal
                closeCB={() => setCreateAthleteModal(false)}
                title={'Create Athlete'}
                children={
                    <CreateAthleteModal
                        coachId={currentUser._id}
                        onClose={() => {
                            setRelationships([]);
                            fetchAndSetRelationships(currentUser);
                            setCreateAthleteModal(false);
                        }}
                    />
                }
            />
        );
    };

    const createInfoModal = () => {
        if (!infoAthleteModal) return null;

        return (
            <Modal
                closeCB={() => setinfoAthleteModal(false)}
                title={'Athlete'}
                children={
                    <InfoAthleteModal
                        relationship={infoAthleteModal}
                        onClose={() => {
                            setRelationships([]);
                            fetchAndSetRelationships(currentUser);
                            setinfoAthleteModal(false);
                        }}
                    />
                }
            />
        );
    };

    if (!relationships) return null;

    return (
        <div className={styles.wrap}>
            <div className={styles.top}>
                <h3>Athletes</h3>
                <div className={styles.actions}>
                    <div className='btn btn-primary' onClick={() => setCreateAthleteModal(true)}>
                        Create New Athlete
                    </div>
                    <div className='btn btn-primary' onClick={() => setInviteAthleteModal(true)}>
                        Add Athlete
                    </div>
                </div>
            </div>
            <input
                type='search'
                onChange={u => setSearch(u.target.value)}
                placeholder='Search...'
                className='island'
                style={{ border: 0, padding: '5px' }}
            ></input>
            <div className={styles.athletesWrap}>{athleteIslands}</div>
            {createUserInviteModal()}
            {createCreationModal()}
            {createInfoModal()}
        </div>
    );
}
